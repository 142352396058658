@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.contractor {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__delete-button { 
    position: absolute;
    top: 0;
    left: 0;
    @include font(1.2rem, 500);
    cursor: pointer;
    letter-spacing: 0.048rem;
    color: $lightGray;
    background-color: transparent;
    border: none;
    text-decoration: underline;
  }

  &__back_btn {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: absolute;
    top: 6.5rem;
    background-color: transparent;
    color: $tertiary;
    border: none;
    cursor: pointer;
    svg {
      margin-right: 0.5rem;
      margin-bottom: 0.1rem;
    }
  }
  &__content {
    width: 119rem;
    padding: 1.9rem;
  }

  &__button-and-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.5rem;
    width: 33rem;
  }
  &__button {
    width: 18rem;
    @include font(1.4rem, 500);
  }
  &__logo {
    width: 13rem;    margin-left: 2rem;
  }

  &__content-wrapper {
    display: flex;
    align-items: flex-end;
    .contractor-left-wrapper {
      width: 38.8rem;
      margin-right: 1rem;

      .contractor-information {
        height: 38rem;
        padding: 1.2rem;
        margin-bottom: 1rem;
        .contractor-information-header {
          display: flex;
          &__left-container {
            width: 90%;
          }
          &__right-container {
            display: flex;
            justify-content: flex-end;
            width: 10%;
          }
          &__edit {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            svg {
              width: 1.6rem;
              height: 1.6rem;
              fill: $malibuBlue;
            }
            &-text {
              @include font(0.9rem, 500);
              color: $contractorLightBlueStatus;
              margin-top: 0.2rem;
            }
          }

          &__title {
            @include font(1.6rem, 600);
            margin-bottom: 0.5rem;
          }
          &__clientid {
            @include font(1.2rem, 500);
            margin-bottom: 0.5rem;
          }
          &__market {
            @include font(1.4rem, 500);
            margin-bottom: 0.5rem;
            &--bold {
              font-weight: bold;
              color: $labelText;
            }
          }
        }
        &__content {
          .contractor-information__row {
            display: flex;
          }
          margin-top: 2rem;
          .contractor-information-field {
            min-width: 50%;
            margin-bottom: 3.5rem;
            &__label {
              color: $labelText;
              @include font(1.2rem, 600);
              margin-bottom: 1rem;
            }
            &__text {
              @include font(1.4rem, 500);
            }
          }
        }
      }
      .contractor-chart {
        width: 38.8rem;
        height: 16.4rem;
        display: flex;
        padding: 2rem;
        &__left-container {
          width: 60%;
        }

        &__right-container {
          width: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        &__title {
          @include font(1.6rem, 600);
        }
      }
    }
    .contractor-right-wrapper {
      width: 79.1rem;
      .contractor-documents {
        width: 79.1rem;
        height: 52.2rem;
        padding: 2.3rem 1.85rem;
      }
    }
  }
  &-filters {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 2.8rem;
    &__text {
      @include font(1.1rem, 500);
      margin-left: 0.5rem;
      margin-right: 1.2rem;
    }
    &__icon {
      cursor: pointer;
    }
    &__delete-button {
      cursor: pointer;
      color: $textMedium;
      @include font(12, 500);
      background-color: transparent;
      border: none;
      text-decoration: underline;
      margin-left: 1.2rem;
    }
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0 0;
    margin-bottom: 1rem;
  }
  &-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &__title {
      @include font(1.6rem, 500);
    }
  }
  &__spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 39.7rem;
  }
  &__no-docs-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20rem;
    text-align: center;
  }
  .contractor-table {
    &__expiring {
      display: flex;
      justify-content: center;
      border-radius: 0.4rem;
      padding: 0.5rem 0;
      margin: 0 1rem;
      @include font(1rem, 600);
      color: $errorDark;
      border: solid 0.1rem $auburn;
      background-color: $alertLight;
      &--30 {
        border: solid 0.1rem $burntOrange;
        background-color: $cautionLight;
        color: $cautionDark;
      }
      &--60 {
        border: solid 0.1rem $warning;
        background-color: $warningLight;
        color: $warningDark;
      }
      &--90 {
        border: solid 0.1rem $success;
        background-color: $successLight;
        color: $messageDark;
      }
      &--current {
        background-color: $currentStatusBackground;
        border: 0.1rem solid $borderViolet;
        color: $black;
      }
    }
  }
}
