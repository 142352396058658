@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.rectangle-checkbox {
  width: 6rem;
  height: 4rem;
  &__label {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.2rem;
    border-radius: 0.5rem;
    border: solid 0.1rem $filtersBorder;
    width: 6rem;
    font-size: 1rem;
    line-height: 1.2;
    cursor: pointer;
    &::selection {
      color: none;
      background: none;
    }
    &::-moz-selection {
      color: none;
      background: none;
    }
  }
  &__input {
    width: 0rem;
    height: 0rem;
    display: none;
  }
}
