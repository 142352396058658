@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.dropzone-container {
  width: 100%;
  height: 29rem;
  background-color: $textLight;
  border-radius: 0.7rem;
  color: $secondary;
  padding: 1.85rem 3.4rem;
  position: relative;
  &--error {
    animation: error 1.1s forwards;
    outline: 0.1rem solid $inputBorderError;
    border: 0.1rem solid $inputBorderError;
  }
  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__error {
      font-size: 1.1rem;
      font-weight: normal;
      font-family: 'Libre Franklin', sans-serif;
      position: absolute;
      bottom: -2rem;
      text-align: left;
      color: $white;
    }
    svg {
      width: 5rem;
    }
    &__file-name {
      @include font(1rem, 500);
      text-align: center;
      margin-top: 0.5rem;
    }
    &__description {
      margin-top: 3.5rem;
      margin-bottom: 2rem;
      @include font(1.4rem, 500);
    }
    &__or {
      margin-bottom: 2.5rem;
      @include font(1.4rem, 600);
    }
  }
}
