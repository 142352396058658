@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.radio {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__checkmark {
    position: relative;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    background-color: transparent;
    border: 0.1rem solid $inputBackground;
    border-radius: 50%;
  }
  &__label {
    padding-left: 1rem;
    max-width: 90%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover &__checkmark {
    background-color: $inputBackground;
  }
  &__input:checked ~ &__checked:after {
    content: '';
    position: absolute;
    top: 0.4rem;
    left: 0.3rem;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background: $inputBackground;
  }
}
