@use '../../sass/utils' as *;
@use '../../sass/colors' as *;

.password-protected {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .about {
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      width: 8.2rem;
    }
    p {
      @include font(1.1rem);
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: clamp(20rem, 50%, 40rem);
    gap: 2rem;
  }
  .password-protected__title {
    @include font(2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }
  .password-protected__subtitle {
    @include font(1.4rem);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 5rem;
    text-align: center;
    a {
      color: $diamond;
    }
  }
}
