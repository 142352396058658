@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.dashboard-pie-chart {
  &__label-top {
    transform: translateY(0.5rem);
    tspan {
      fill: $inputBackground;
      @include font(2.8rem, 600);
    }
  }
  &__label-bottom {
    transform: translateY(1rem);
    tspan {
      fill: $inputBackground;
      @include font(0.7rem, 500);
    }
  }
}
