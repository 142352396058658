@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.home {
  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;

    &__logo {
      width: 13rem;    }
  }
  &-information {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &__item {
      display: flex;
      width: 38.8rem;
      height: 15rem;
      padding: 1.2rem 2rem;
    }
    &__title {
      @include font(1.6rem, 600);
    }
    &__file-name {
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;
    }
    &__upload-info {
      @include font(1.2rem, 500);
      color: $textMedium;
    }

    &__left-container {
      width: 60%;
    }
    &__right-container {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &__path-name {
      @include font(1rem, 500);
      text-align: center;
      color: $tertiary;
    }
    &__view-doc {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      @include font(1.3rem, 500);
      cursor: pointer;
      svg {
        height: 1.2rem;
        margin-right: 0.3rem;
      }
    }
  }
  .home-table {
    &__content {
      width: 119rem;
      padding: 1.1rem 1.9rem;
    }
    &__spinner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 27.3rem;
    }
    &__expiring {
      display: flex;
      justify-content: center;
      border-radius: 0.4rem;
      padding: 0.5rem 0;
      margin: 0 1rem;
      @include font(1rem, 600);
      color: $errorDark;
      border: solid 0.1rem $auburn;
      background-color: $alertLight;
      &--30 {
        border: solid 0.1rem $burntOrange;
        background-color: $cautionLight;
        color: $cautionDark;
      }
      &--60 {
        border: solid 0.1rem $warning;
        background-color: $warningLight;
        color: $warningDark;
      }
      &--90 {
        border: solid 0.1rem $success;
        background-color: $successLight;
        color: $messageDark;
      }
      &--current {
        background-color: $currentStatusBackground;
        border: 0.1rem solid $borderViolet;
        color: $black;
      }
    }

    &-filters {
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 2.8rem;
      &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 85%;
    
      }
      &__report-btn-wrapper {
        width: 15%;
      }
      &__report-btn {
        margin-left: 2rem;
        width: 14rem;
        text-overflow: "no-wrap";
        @include font(1.1rem, 500);
        padding: 0.6rem;

      }
      &__text {
        @include font(1.1rem, 500);
        margin-left: 0.5rem;
        margin-right: 1.2rem;
      }
      &__icon {
        cursor: pointer;
      }
      &__delete-button {
        cursor: pointer;
        color: $textMedium;
        @include font(1.2rem, 500);
        background-color: transparent;
        border: none;
        text-decoration: underline;
        margin-left: 1.2rem;
      }
    }
    &__pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem 0 0;
      margin-bottom: 1rem;
    }
    &-table-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      &__title {
        @include font(1.6rem, 500);
      }
    }
  }
}
