@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.dashboard-pie-legend {
  margin-top: 2rem;
  &__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include font(1rem, 500);
    color: $textLight;
    margin: 0.4rem 2rem 0.4rem 0;
  }
  &__description {
    display: flex;
    align-items: center;
  }
  &__color-circle {
    width: 1rem;
    height: 1rem;
    background-color: $error;
    border: 0.1rem $inputBackground solid;
    border-radius: 50%;
    margin-right: 1rem;
  }
  &__value {
    @include font(1rem, 600);
  }
}
