@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

header {
  &.sidebar {
    background-color: $secondary;
    color: $textLight;
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .sidebar-navigation {
    display: grid;
    grid-template-rows: 1fr auto 1fr auto 1fr;
    max-height: 60rem;

    &__wrapper {
      overflow: hidden;
      height: 100%;
    }

    :first-child {
      align-self: flex-end;
    }

    :last-child {
      align-self: flex-start;
    }

    height: 100%;
  }

  .sidebar-button {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 2rem 0rem 2rem 2.8rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:focus-visible,
    &:hover {
      background-color: transparentize($primary, 0.6);
      outline: none;

      .sidebar-button__icon {
        fill: $inputBackground;
      }
    }

    &.active {
      background-color: $primary;

      .sidebar-button__icon {
        fill: $inputBackground;
      }
    }

    &--exit {
      width: 100%;
    }

    &__icon {
      transition: all 0.2s ease-in-out;
      fill: $malibuBlue;
    }

    &__icon {
      min-width: 2rem;
      min-height: 2rem;
    }

    &__text {
      @include font(1.4rem, 600, 'Libre Franklin');
      color: $textLight;
      margin-top: 0.2rem;
      overflow: hidden;
      padding-left: 3rem;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--menu {
      margin: 2rem;
      padding: 0.5rem;
      width: min-content;

      &:hover {
        background-color: transparent;
      }

      svg {
        overflow: visible;
        width: 2.5rem;
      }
    }
  }

  .sidebar-logo {
    height: 10rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    svg {
      width: 2.7rem;
    }
    &--is-open {
      animation: fadeIn 1s;
      padding-left: 2.8rem;
      align-items: flex-start;
      flex-direction: column;

      svg {
        width: 6.5rem;
      }
    }
  }
  .sidebar-rights {
    @include font(1.1rem, 500);
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
