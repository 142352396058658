@import '../../sass/colors';
@import '../../sass/utils';

.authentication {
  background-color: $textLight;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: $textLight;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &--hidden {
    display: none;
  }
}

.signup-area {
  width: 99.5rem;
  height: 55.1rem;
  border-radius: 0.8rem;
  background-image: linear-gradient(128deg, #18245f, #000a3c);
  opacity: 0.99;

  &--background {
    height: 100%;
    border-radius: 0.8rem;
    background-image: url('./../../assets/images/background/map_m.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .form {
      width: 89rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;

      &__title {
        color: $textLight;
        @include font(2.5rem, 500);
      }

      &__close-button {
        fill: $inputBackground;

        cursor: pointer;
        position: absolute;
        top: -2.8rem;
        right: -3.5rem;
      }

      .credentials {
        width: 100%;
        display: flex;
        margin: 3rem 0;

        &__left {
          margin-right: 3rem;
          width: 60rem;
          display: flex;
          flex-wrap: wrap;
        }

        &__right {
          width: 33%;
        }
        &__personal {
          margin-bottom: 3rem;
        }

        &__personal,
        &__company {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &__field {
          width: 27.5rem;
          margin-bottom: 1.8rem;
          &--password {
            margin-bottom: 4.6rem;
          }
        }
        &__button-container {
          width: 30rem;
        }
      }
    }
  }
}
