@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.settlements {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    width: 119rem;
    padding: 1.9rem;
  }

  &__buttons-and-logo {
    width: 72rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.5rem;
  }
  &__logo {
    width: 13rem;    margin-left: 1rem;
  }
  &__button {
    width: 27.5rem;
    @include font(1.4rem, 500);
  }
  &-filters {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 2.8rem;
    &__text {
      @include font(1.1rem, 500);
      margin-left: 0.5rem;
      margin-right: 1.2rem;
    }
    &__icon {
      cursor: pointer;
    }
    &__delete-button {
      cursor: pointer;
      color: $textMedium;
      @include font(12, 500);
      background-color: transparent;
      border: none;
      text-decoration: underline;
      margin-left: 1.2rem;
    }
  }
  &__pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem 0 0;
    margin-bottom: 1rem;
  }
  &-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    &__title {
      @include font(1.6rem, 500);
    }
  }
  &__spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 39.7rem;
  }
}
