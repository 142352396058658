@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.button {
  width: 100%;
  border-radius: 1.8rem;
  padding: 1rem;
  border: solid 0.1rem $malibuBlue;
  background-color: $primary;
  color: $textLight;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(1.4rem, 600);
  transition: all 0.5s;
  &:disabled {
    background-color: $backgroundLight;
  }

  &:active {
    transform: scale3d(1.05, 1.05, 1.05);
  }
  &:hover {
    opacity: 0.95;
  }
  &--with-icon {
    justify-content: flex-start;
  }
  svg {
    fill: $inputBackground;
    margin-right: 1rem;
    margin-left: 0.5rem;
    height: 2rem;
  }
}
