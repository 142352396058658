@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  -webkit-appearance: none;
  &__field-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__icon {
    width: 1.8rem;
    height: 1.8rem;
    &-button {
      fill: $darkGray;
      display: grid;
      place-items: center;
      margin: 0;
      right: 0;
      position: absolute;
      margin-right: 0.8rem;
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 1rem;
    }
  }
  &__required,
  &__label {
    @include font(1.2rem, bold);
    letter-spacing: 0.024rem;
    text-align: left;
    color: $labelText;
    margin-bottom: 0.7rem;
    min-height: 1.52rem;
  }
  &--invalid.input--touched {
    .input__field {
      outline: 0.1rem solid $inputBorderError;
      border: 0.1rem solid $inputBorderError;
      animation: input-error 1.1s forwards;
      &:focus-visible {
        outline: solid 0.2rem $inputBorderError;
        background-color: saturate($inputErrorBackground, 50);
      }

    }
  }
  &__field {
    &:-internal-autofill-selected {
      background-color: $textLight !important;
    }
    @include font(1.6rem, 600);
    width: 100%;
    -webkit-appearance: none;
    padding: 0.7rem 2rem;
    min-height: 3.56rem;
    color: $darkGray;
    border-radius: 3rem;
    border: 0.1rem solid $inputBorder;
    &::placeholder {
      color: $darkGray;
    }
    &:focus-visible {
      outline: solid 0.2rem $inputBorder;
    }
    &:disabled {
      background-color: $darkJeans;
    }
  }
  &__error {
    @include font(1.1rem, normal);
    margin-left: 2rem;
    position: absolute;
    top: 6rem;
    text-align: left;
    color: $textLight;
  }
}

@keyframes input-error {
  0% {
    background-color: darken($inputErrorBackground, 12);
  }
  100% {
    background-color: $inputErrorBackground;
  }
}
