@use '../../../../sass/colors' as *;
@use '../../../../sass/utils' as *;

.add-edit-contractor {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    width: 13rem;  }
  &__back_btn {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: absolute;
    top: 6.5rem;
    background-color: transparent;
    color: $tertiary;
    border: none;
    cursor: pointer;
    svg {
      margin-right: 0.5rem;
      margin-bottom: 0.1rem;
    }
  }
  &__content {
    width: 119rem;
    height: 53.8rem;
    padding: 3rem 1.5rem;
  }
  &__info {
    display: flex;
  }
  &__title {
    @include font(2.5rem, 500);
    margin-bottom: 2.2rem;
  }
  &-row {
    display: flex;
    margin-bottom: 2.7rem;
    justify-content: flex-start;
    &--second {
      margin-bottom: 5rem;
    }
  }
  &__input-box {
    display: flex;
    width: 25%;
    padding-right: 1.4rem;
    &--market {
      padding-right: 0;
      padding-left: 7.3rem;
    }
  }
  &__button-box {
    display: flex;
    width: 25%;
    padding-right: 1.4rem;
    padding-top: 1.4rem;
  }
  &__state-select {
    padding-right: 1rem;
  }
  &__markets-editing-container {
    padding-left: 7.3rem;
    width: 25%;
    .add-edit-contractor__input-box {
      width: 100%;
      padding: 0rem;
    }
  }
  &__edit-button {
    cursor: pointer;
    color: $textMedium;
    @include font(12, 500);
    background-color: transparent;
    border: none;
    text-decoration: underline;
  }
}
