@import '../../sass/colors';
@import '../../sass/utils';

.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: $inputBackground;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &--qr {
    .verification__container {
      height: 65.3rem;
    }
    .verification-input {
      padding: 3.3rem 0;
    }
  }

  &__container {
    width: 87rem;
    height: 59.5rem;
    background-image: $gradient;
    border-radius: 0.8rem;
    position: relative;
  }

  &__top-message {
    background-color: $cautionLight;
    color: $cautionDark;
    position: absolute;
    width: 100%;
    @include font(1.8rem, bold);
    display: flex;
    align-items: center;
    padding: 1.8rem 3.5rem;
    border-radius: 0.8rem 0.8rem 0 0;
    svg {
      margin-right: 1.2rem;
      width: 3rem;
    }

    span {
      margin-right: 2.2rem;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 2rem 8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message {
      @include font(1.5rem, normal);
      color: $tertiary;
      margin: 1.6rem 0;
    }

    .title {
      @include font(1.6rem, bold);
      color: $tertiary;
      margin-bottom: 1.2rem;
      width: 100%;
    }
  }

  &__links {
    .help {
      padding: 0.5rem 0;
      @include font(1.4rem);
      text-align: center;
      color: $textLight;
      &__resend {
        cursor: pointer;
      }

      a {
        color: $tertiary;
      }
    }
  }

  .padding {
    padding-bottom: 5rem;
  }
}
