@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.pagination {
  display: flex;
  align-items: center;
  svg {
    margin: 0 0.7rem;
    cursor: pointer;
  }
  &__item {
    @include font(1.4rem, 500);
    margin: 0 0.35rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $textMedium;
    &--selected {
      border-radius: 50%;
      background-color: $malibuBlue;
      color: $textLight;
    }
  }
}
