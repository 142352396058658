@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.toggle {
  width: 8.6rem;
  height: 3.4rem;
  &-input {
    width: 0;
    height: 0;
    display: none;
  }
  &-label {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    border-radius: 1.7rem;
    cursor: pointer;
    background-color: $tertiary;
    color: $darkGray;
    &__switcher {
      width: 2.8rem;
      height: 2.8rem;
      background-color: $toggleBackground;
      box-shadow: -0.3rem 0 0.5rem 0 rgba(0, 0, 0, 0.29);
      position: absolute;
      border-radius: 7rem;
      border: solid 0.1rem $primary;
      top: 0.3rem;
      left: 0.3rem;
      transition: 0.5s;
    }
    &__text {
      padding: 0 1.5rem;
      font-size: 1.5rem;
      font-weight: bold;
      &::selection {
        color: none;
        background: none;
      }
      &::-moz-selection {
        color: none;
        background: none;
      }
    }
    &--checked {
      background-color: $primary;
      color: $tertiary;
      justify-content: flex-start;
    }
  }
}
