@use '../../sass/utils' as *;

.header {
  .company__name {
    @include font(1.4rem, bold);
    margin-bottom: 0.5rem;
  }
  .user__name {
    @include font(1.2rem, normal);
    margin-bottom: 0.5rem;
  }
  padding-bottom: 2.5rem;
}
