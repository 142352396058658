@import '../../sass/colors';
@import '../../sass/utils';

.login {
  background-color: $inputBackground;
  overflow-y: auto;
  height: 100%;
  

}
.container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-area {
    width: 56.6rem;
    height: 48.9rem;
    border-radius: 0.8rem;
    background-image: $gradient;
    opacity: 0.99;

    &--background {
      height: 100%;
      border-radius: 0.8rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      .form {
        width: 31.3rem;
        height: 40rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        &__title {
          color: $textLight;
          @include font(2.5rem, 500);
          text-align: center;
        }

        &__input-box {
          width: 100%;
        }

        &__label {
          @include font(1.2rem, bold);
          color: $labelText;
          letter-spacing: 0.024rem;
        }

        &__input {
          margin-top: 0.7rem;
          background-color: $inputBackground;
          height: 4rem;
          display: flex;
          align-items: center;
          border-radius: 2rem;

          input {
            border: none;
            @include font(1.6rem);
            width: 100%;
            margin-left: 2rem;
            margin-right: 0.4rem;
            height: 2rem;

            &:focus-visible {
              outline: none;
            }
          }

          &-icon {
            &-button {
              margin-right: 0.8rem;
              cursor: pointer;
              border: none;
              background-color: transparent;
              padding: 0;
            }
          }
        }

        &__submit-button {
          width: 100%;
          @include font(1.4rem);
          color: $textLight;
          height: 3.6rem;
          border: 0.1rem solid $inputBorder;
          border-radius: 1.8rem;
          background-color: $primary;
          cursor: pointer;
        }

        .link-area {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &__link {
            text-decoration: none;
            @include font(1.2rem);
            color: $tertiary;
          }
        }

        .about {
          display: flex;
          align-items: center;
          flex-direction: column;
          svg {
            width: 8.2rem;
          }
          p {
            @include font(1.1rem);
          }
        }
      }
    }
  }
}
