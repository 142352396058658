@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  &__input {
    background-color: $textLight;
    cursor: pointer;
    @include font(1.6rem, 600);
    width: 100%;
    padding: 0.7rem 2rem;
    min-height: 3.56rem;
    color: $darkGray;
    border-radius: 3rem;
    border: 0.1rem solid $inputBorder;

    &::placeholder {
      color: $darkGray;
    }
    &:focus-visible {
      outline: solid 0.2rem $inputBorder;
    }
  }
  &--invalid.select--touched {
    .select__input {
      outline: 0.1rem solid $inputBorderError;
      border: 0.1rem solid $inputBorderError;
      animation: select-error 1.1s forwards;
      &:focus-visible {
        outline: solid 0.2rem $inputBorderError;
        background-color: saturate($inputErrorBackground, 50);
      }
    }
  }
  &__label {
    @include font(1.2rem, bold);
    letter-spacing: 0.024rem;
    text-align: left;
    color: $labelText;
    margin-bottom: 0.7rem;
  }
  &__arrow {
    position: absolute;
    top: 3.9rem;
    right: 2rem;
    height: 0.5rem;

    fill: $primary;
  }
  &__options {
    max-width: 17rem;
    position: absolute;
    top: 6.2rem;
    right: 1rem;
    background-color: $tertiary;
    border-radius: 0.4rem;
    z-index: 1;
    max-height: 15rem;
    overflow: auto;
    &-arrow {
      position: absolute;
      top: 5.8rem;
      height: 0.5rem;
      right: 2rem;
      fill: $tertiary;
    }
    &--with-categories {
      width: 27rem;
      max-width: 27rem;
      max-height: 32rem;
    }
  }
  &__option {
    min-width: 10rem;
    padding: 1rem;
    color: $textDark;
    @include font(1.2rem, 500);
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &--selected {
      color: $textLight;
      background-color: $primary;
    }
    &--category {
      background-color: $background;
      text-transform: uppercase;
      color: $white;
      padding: 0.4rem;
      &:hover {
        background-color: $background;
      }
    }
    &--category-item {
      padding: 0.4rem;
    }
  }
  &__error {
    @include font(1.1rem, normal);
    margin-left: 2rem;
    position: absolute;
    top: 6rem;
    text-align: left;
    color: $textLight;
  }
}

@keyframes select-error {
  0% {
    background-color: darken($inputErrorBackground, 12);
  }
  100% {
    background-color: $inputErrorBackground;
  }
}
