@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.modal-wrapper {
  width: 100vw;
  height: 100%;
  max-height: 100rem;
  overflow: hidden;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $modalWrapperBackground;
}

.modal {
  width: 46.2rem;
  height: 29.2rem;
  background-color: $background;
  background-image: $gradient;
  border-radius: 0.6rem;
  box-shadow: 0rem 0.8rem 1.5rem -0.9rem rgba(21, 21, 22, 1);
  position: relative;
  &--background {
    height: 100%;
    border-radius: 0.8rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__close-button {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    fill: $tertiary;
    cursor: pointer;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem;
    &__buttons {
      display: flex;
      width: 100%;
      padding: 4rem 4rem 0rem 4rem;
      justify-content: space-between;
    }
    &__button {
      width: 14.5rem;
    }
  }
  .modal-content-delete-account,
  .modal-content-accept-payment,
  .modal-content-logout {
    margin-top: 2rem;
    &__question {
      @include font(1.8rem, 500);
      margin-bottom: 1rem;
    }
    &__name {
      @include font(2.5rem, 600);
    }
  }

  &.modal-upload-spreadsheet {
    width: 75.6rem;
    height: 49.5rem;
  }

  &.modal-edit-document-types {
    width: 71.5rem;
    height: 39rem;
  }

  .modal-content-upload-spreadsheet {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    form {
      width: 100%;
      height: 100%;
    }
    &__title {
      @include font(2.6rem, 600);
    }
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      &-left {
        width: 31.6rem;
        height: 100%;
        padding: 4.4rem 4.4rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-right {
        width: 39rem;
        padding-top: 4.4rem;
      }
    }
    &__date-range {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      .input {
        width: 18rem;
      }
    }
    &__button-save {
      margin-bottom: 1rem;
    }
  }

  &.modal-upload-document {
    width: 105.8rem;
    height: 49.5rem;
  }

  .modal-content-upload-document {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    form {
      width: 100%;
      height: 100%;
    }
    &__title {
      @include font(2.6rem, 600);
    }
    &__label {
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.024rem;
      text-align: left;
      color: $lavender;
      margin-bottom: 0.7rem;
      min-height: 1.52rem;
    }
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      &-left {
        width: 41.6rem;
        height: 100%;
        padding: 4.4rem 4.4rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-right {
        display: flex;
        width: 100%;
        padding-top: 4.4rem;
        .input,
        .select {
          margin-bottom: 2.7rem;
        }
      }
    }
    &__inputs-left {
      width: 31rem;
      margin-right: 5.7rem;
      .new-document-input {
        margin-bottom: 3.5rem;
      }
    }
    &__inputs-right {
      width: 31rem;
      .expiration-date-document-input {
        margin-bottom: 1.5rem;
      }
    }
    &__edit-button {
      cursor: pointer;
      color: $textMedium;
      @include font(1.2rem, 500);
      background-color: transparent;
      border: none;
      text-decoration: underline;
    }
  }

  .modal-content-edit-markets {
    &__name {
      text-align: left;
      width: 100%;
      padding: 1rem 4rem;
    }
    &__checkboxes-container {
      display: flex;
    }
    &__checkboxes {
      width: 100%;
      padding: 1rem 4rem;
      overflow: auto;
    }
    .checkbox-wrapper {
      margin: 0.8rem 0;
      display: flex;
      justify-content: flex-start;
      label {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .modal-content-edit-document-types {
    &__name {
      text-align: left;
      width: 100%;
      padding: 1rem 4rem;
    }
    &__checkboxes-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 4rem;
    }
    &__checkboxes {
      width: 100%;
      padding-top: 1rem;
      overflow: auto;
      h3 {
        @include font(1.4rem, 600);
        white-space: nowrap;
        padding-left: 0.5rem;
      }
    }
    &__buttons {
      width: 40rem;
    }
    &__no-docs {
      padding: 2rem 0.5rem;
    }
    .checkbox-wrapper {
      margin: 0.8rem 0;
      display: flex;
      justify-content: flex-start;
      label {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
