@use './sass/colors' as *;
@use './sass/utils' as *;

:root {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body,
#root,
.App,
.dashboard {
  @include font();
  height: 100%;
  max-height: 100rem;
  width: 100%;
  color: $textLight;
}

#root {
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: $success;
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: $warning;
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: $error;
  }
}

@keyframes error {
  0% {
    background-color: darken($inputErrorBackground, 12);
  }
  100% {
    background-color: $inputErrorBackground;
  }
}
