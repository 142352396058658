$diamond: #edf2ff;
$lavender: #bac8ff;
$malibuBlue: #91a7ff;
$blueberry: #5c7cfa;
$darkJeans: #3d4982;
$blueGlass: #7680b650;
$cetaceanBlue: #000a3c;
$mistyRose: #ffe5e8;
$rose: #e6aeb5;
$pinotNoir: #b11327;
$auburn: #a0282d;
$merlot: #57030d;
$mellowApricot: #ffbb76;
$burntOrange: #b9541f;
$milkChocolate: #5f2202;
$lemonade: #ffe8a8;
$honey: #b9901f;
$darkBronze: #573900;
$aquamarine: #9be5cb;
$crayolaGreen: #17a577;
$darkEvergreen: #022c1e;
$white: #ffffff;
$lightGray: #f5f5f5;
$gray: #808080;
$darkGray: #5c5c5c;
$black: #000000;
$transparent: transparent;

$gradient: linear-gradient(128deg, #18245f, #000a3c);


$primary: $blueberry;
$secondary: $cetaceanBlue;
$tertiary: $diamond;
$error: $pinotNoir;
$errorDark: $merlot;
$errorLight: $rose;
$alert: $pinotNoir;
$alertDark: $merlot;
$alertLight: $rose;
$success: $crayolaGreen;
$successLight: $aquamarine;
$message: $crayolaGreen;
$messageDark: $darkEvergreen;
$messageLight: $aquamarine;
$caution: $burntOrange;
$cautionDark: $milkChocolate;
$cautionLight: $mellowApricot;
$warning: $honey;
$warningDark: $darkBronze;
$warningLight: $lemonade;

$textLight: $white;
$textDark: $cetaceanBlue;
$textMedium: $lavender;
$labelText: $lavender;
$background: $cetaceanBlue;
$backgroundLight: $darkJeans;
$inputBackground: $white;
$inputErrorBackground: $mistyRose;
$inputBorder: $malibuBlue;
$inputBorderError: $error;
$inputBorderHover: $blueberry;
$inputBorderActive: $blueberry;
$inputBorderFocus: $blueberry;


// unusual colors
$borderViolet: #515bdf;
$contractorLightBlueStatus: #dbe4ff;
$currentStatusBackground: #aeb2e6;
$filtersBorder: #e1ebf3;
$modalWrapperBackground: #2c355d;
$tableBorders: #1a255b;
$toggleBackground: #e8f5f7;