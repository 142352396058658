@import '../../sass/colors';
@import '../../sass/utils';

.verification-item {
  display: flex;
  margin-bottom: 2.8rem;
  align-items: center;

  &__icon {
    width: 8rem;
    display: flex;
    justify-content: center;

    .icon {
      width: 3rem;
    }
  }

  &__content {
    width: 45rem;

    h2 {
      @include font(1.5rem, bold);
      color: $tertiary;
      margin-bottom: 0.6rem;
    }

    p {
      @include font(1.5rem);
      color: $tertiary;
      line-height: 1.47;
      padding-right: 2rem;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 17.5rem;
      height: 3.6rem;
      background-color: $primary;
      border-radius: 1.8rem;
      border: 0.1rem solid $inputBorder;
      color: $textLight;
      cursor: pointer;
    }
  }
}
