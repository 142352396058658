@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.pages-info {
  @include font(1.2rem, 500);
  color: $textMedium;
  &__number {
    font-weight: 600;
  }
}
