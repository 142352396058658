@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.filters {
  width: 24.6rem;
  backdrop-filter: blur(3rem);
  position: fixed;
  overflow-y: auto;
  max-height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  animation-name: slideLeftFilters;
  animation-duration: 0.4s;
  user-select: none;
  &__header {
    height: 7rem;
    border-bottom: solid 0.1rem $filtersBorder;
    display: flex;
    align-items: center;
  }
  &__title {
    @include font(1.8rem, 500);
  }
  &__close-button {
    fill: $inputBackground;
    margin-right: 2rem;
    margin-left: 2rem;
    cursor: pointer;
  }
  .filters-filter {
    padding: 2rem 1rem 2rem 1rem;
    border-bottom: solid 0.1rem $filtersBorder;
    animation-name: slideLeftFilters;
    animation-duration: 0.6s;
    &__title {
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
    }
    .input,
    .select {
      margin-top: 0.5rem;
    }
    &__document-statuses {
      display: flex;
      justify-content: center;
      flex-wrap: wrap-reverse;
      .rectangle-checkbox {
        margin: 0.3rem;
      }
    }
  }
}

@keyframes slideLeftFilters {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
  }
}
