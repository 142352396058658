@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.filter-badge {
  border-radius: 13.1rem;
  border: solid 0.1rem $primary;
  color: $tertiary;
  padding: 0.3rem 0.8rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0.3rem;
  @include font(1.1rem, 500);
  svg {
    margin-left: 0.8rem;
    cursor: pointer;
  }
}
