@import '../../sass/colors';
@import '../../sass/utils';

.verification-input {
  display: flex;
  flex-direction: column;
  width: 42rem;
  padding: 5rem 0;

  .title {
    @include font(2.6rem);
    color: $tertiary;
    text-align: center;
  }

  .message {
    @include font(1.6rem);
    color: $tertiary;
    text-align: center;
    margin-top: 0.5rem;
  }

  .subtitle {
    @include font(1.6rem, bold);
    color: $textLight;
    text-align: center;
    margin: 1.6rem 0;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  img {
    width: 12.5rem;
    margin: 0.75rem auto;
  }

  form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .otp-field {
      display: flex;

      input {
        width: 5rem;
        height: 7rem;
        font-size: 2.8rem;
        text-align: center;
        padding: 1rem;
        margin: 0.5rem;
        border-radius: 0.3rem;
        outline: none;
        border: none;
        transition: all 0.1s;

        &:focus {
          border: 0.2rem solid $inputBorderFocus;
          box-shadow: 0 0 0.2rem 0.2rem $inputBorderFocus;
        }
      }
    }

    button {
      width: 17.5rem;
      @include font(1.4rem);
      color: $textLight;
      height: 3.6rem;
      border: 0.1rem solid $inputBorder;
      border-radius: 1.8rem;
      background-color: $primary;
      cursor: pointer;
      margin: 2rem 0;
    }
  }
}
