@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.search-input {
  border-bottom: solid 0.1rem $inputBorder;
  display: flex;
  align-items: center;
  width: 32rem;
  svg {
    fill: $malibuBlue;
    margin-right: 1rem;
  }
  &__field {
    background: transparent;
    border: none;
    color: $textLight;
    width: 90%;
    &::placeholder {
      color: $textLight;
      @include font(1.2rem, 500);
    }
    &:focus {
      outline: none;
    }
  }
}
