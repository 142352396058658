.dashboard {
  display: grid;
  grid-template-columns: auto 1fr;
  background-position: center;
  background-size: cover;
  overflow-y: auto;
  .main {
    padding: 2rem 5rem;
  }
}
