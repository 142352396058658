@mixin animation($animation) {
  @media (prefers-reduced-motion: no-preference) {
    animation: $animation;
  }
}
@mixin transition($transition) {
  @media (prefers-reduced-motion: no-preference) {
    transition: $transition;
  }
}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex($direction: row, $justify: space-around, $align: center, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin quickGrid($columns: 1, $rows: 1, $gap: 0, $rowSize: auto, $columnSize: auto) {
  display: grid;
  grid-template-columns: repeat($columns, $columnSize);
  grid-template-rows: repeat($rows, $rowSize);
  gap: $gap;
}

@mixin grid($columns: 1fr, $rows: 1fr, $gap: 0, $align: center, $justify: center) {
  display: grid;
  grid-template-columns: $columns;
  grid-template-rows: $rows;
  gap: $gap;
  align-items: $align;
  justify-items: $justify;
}

@mixin reset {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin font($size: 1.4rem, $weight: normal, $family: 'Libre Franklin') {
  font-size: $size;
  font-weight: $weight;
  font-family: $family, sans-serif;
}
