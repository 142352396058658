@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
  &__item {
    position: absolute;
    width: 0.6rem;
    height: 0.6rem;
    background: $white;
    border-radius: 50%;
    animation: spinner-animation 1.2s linear infinite;
    &:nth-child(1) {
      animation-delay: 0s;
      top: 3.7rem;
      left: 6.6rem;
    }
    &:nth-child(2) {
      animation-delay: -0.1s;
      top: 2.2rem;
      left: 6.2rem;
    }
    &:nth-child(3) {
      animation-delay: -0.2s;
      top: 1.1rem;
      left: 5.2rem;
    }
    &:nth-child(4) {
      animation-delay: -0.3s;
      top: 0.7rem;
      left: 3.7rem;
    }
    &:nth-child(5) {
      animation-delay: -0.4s;
      top: 1.1rem;
      left: 2.2rem;
    }
    &:nth-child(6) {
      animation-delay: -0.5s;
      top: 2.2rem;
      left: 1.1rem;
    }
    &:nth-child(7) {
      animation-delay: -0.6s;
      top: 3.7rem;
      left: 0.7rem;
    }
    &:nth-child(8) {
      animation-delay: -0.7s;
      top: 5.2rem;
      left: 1.1rem;
    }
    &:nth-child(9) {
      animation-delay: -0.8s;
      top: 6.2rem;
      left: 2.2rem;
    }
    &:nth-child(10) {
      animation-delay: -0.9s;
      top: 6.6rem;
      left: 3.7rem;
    }
    &:nth-child(11) {
      animation-delay: -1s;
      top: 6.2rem;
      left: 5.2rem;
    }
    &:nth-child(12) {
      animation-delay: -1.1s;
      top: 5.2rem;
      left: 6.2rem;
    }
  }
}

@keyframes spinner-animation {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
