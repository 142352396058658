@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.table-relative-wrapper {
  position: relative;
}

.table-wrapper {
  width: 92%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  width: 100%;

  th {
    text-align: left;
    @include font(1.2rem, 600);
    padding-bottom: 1rem;
    color: $textMedium;
  }
  td {
    border-bottom: 0.2rem solid $tableBorders;
    border-collapse: collapse;
    padding: 0.6rem 0.5rem 1rem 0.5rem;
    min-width: 13rem;
    white-space: nowrap;
  }
  tr:first-of-type {
    border-top: 0.2rem solid $tableBorders;
  }
  tr > td:last-of-type {
    border-bottom: none;
    z-index: 10;
    width: 10rem;
    position: absolute;
    right: -2rem;
    .column-icon-container {
      display: flex;
      justify-content: flex-end;

      span {
        text-align: center;
      }
    }

    .column-icon {
      color: $white;
      background: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 1rem;
      min-width: 4rem;
      &--contractor {
        margin-right: 0;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
        margin-bottom: 0.2rem;
      }
      span {
        @include font(0.8rem, 300);
      }
    }
  }

  &__sorting-arrows {
    margin-right: 0.7rem;
    margin-bottom: 0.1rem;
    cursor: pointer;
  }
}
