@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.account {
  &-header {
    width: 102.3rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    &__logo {
      width: 13rem;    }
  }
  &__content {
    width: 102.3rem;
    height: 55.8rem;
    padding: 3rem 1.5rem;
  }
  &__info {
    display: flex;
  }
  &__title {
    @include font(2.5rem, 500);
    margin-bottom: 2.2rem;
  }
  &__centered-copyright {
    width: 102.3rem;

  }

  &__delete-button {
    position: absolute;
    top: 0;
    left: 0;
    @include font(1.2rem, 500);
    cursor: pointer;
    letter-spacing: 0.048rem;
    color: $lightGray;
    background-color: transparent;
    border: none;
    text-decoration: underline;
  }
  &__save {
    width: 27.5rem;
    margin-top: 1.8rem;
  }

  .user {
    width: 60rem;
    margin-right: 6rem;
    &__fields-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &--password-container {
        margin-bottom: 3rem;
      }
    }
    &__field {
      width: 27.5rem;
      margin-bottom: 1.6rem;
    }
  }

  .notifications {
    width: 28.8rem;
    &__title {
      @include font(1.2rem, 600);
      line-height: normal;
      letter-spacing: 0.024rem;
      color: $textMedium;
      margin-bottom: 0.8rem;
    }
    &__options {
      display: flex;
      margin-bottom: 1.5rem;
      justify-content: space-between;
    }

    .notifications-preferences {
      &__options {
        display: flex;
        flex-direction: column;
      }
      &__option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;
      }
      &__description {
        display: flex;
        @include font(1.4rem, 500);
        svg {
          margin-right: 1rem;
          width: 2rem;
          height: 2rem;
          fill: $tertiary;
        }
      }
    }
  }
}
