@use '../../sass/colors' as *;
@use '../../sass/utils' as *;

.contractor-document-status {
  width: 7rem;
  border-radius: 0.4rem;
  padding: 0.15rem 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $contractorLightBlueStatus;
  border: 0.1rem solid $borderViolet;
  color: $borderViolet;
  @include font(1rem, 600);

  &__count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $borderViolet;
    color: $contractorLightBlueStatus;
    text-align: center;
    border-radius: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    @include font(0.8rem, 600);
  }
  &--expired {
    background-color: $rose;
    border: 0.1rem solid $pinotNoir;
    color: $merlot;
    .contractor-document-status__count {
      color: $rose;
      background-color: $pinotNoir;
    }
  }
  &--expiring {
    background-color: $mellowApricot;
    border: 0.1rem solid $burntOrange;
    color: $burntOrange;
    .contractor-document-status__count {
      color: $mellowApricot;
      background-color: $burntOrange;
    }
  }
}
