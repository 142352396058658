@use './sass/colors' as *;
@use './sass/utils' as *;

* {
  box-sizing: border-box;
}

.App {
  background-image: $gradient;
  overflow-y: auto;
}

.glass {
  border-radius: 0.8rem;
  backdrop-filter: blur(0.6rem);
  border: solid 0.1rem $malibuBlue;
  background-color: $blueGlass;
}

.centered-copyright {
  display: flex;
  justify-content: center;
  margin-top: 0.7rem;
  font-size: 0.9rem;
  position: relative;
}

html {
  font-size: 10px;
}

a {
  text-decoration: none;
  color: $white;
}
